<!-- eslint-disable -->
<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />
        <div class="w-50-l w-90">
            <!-- Simplebks logo -->
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>
            <!-- Simplebks logo -->

            <!-- Verification input -->
            <div class="pa2" style="background-color: white; border-radius: 5px;">
                <div class="tc pt4 pb2">
                    <img src="../../assets/images/email-img.svg" width="60px" alt="simplebks-logo" />
                </div>
                <div class="tc pb3">
                    <h2 class="lh-copy">Verify your email address to get started</h2>
                    <div class="alert alert-info lh-copy">
                        Please provide the six-digit code that was sent to your email address. If you did not receive it, kindly
                        check your spam or junk folder, as well as your promotional messages folder.
                    </div>
                </div>

                <form id="form" @submit.prevent="onSubmit">
                    <div class="flex items-center justify-center tc">
                        <v-otp-input
                            ref="otpInput"
                            v-model:value="bindModal"
                            input-classes="otp-input form-sign mb3"
                            separator=""
                            :num-inputs="6"
                            :should-auto-focus="true"
                            input-type="letter-numeric"
                            :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
                            :placeholder="['*', '*', '*', '*', '*', '*']"
                            @on-complete="handleOnComplete"
                        />
                        <button class="ml1-l" type="submit" :disabled="state.disableSubmitButton">
                            Verify
                        </button>
                    </div>
                    <div class="tc mt3 mb3">
                        In case you still cannot locate the email, please <a href="" @click.prevent="resendOTP" class="b">resend email</a>
                    </div>
                    <div class="tc mt3 mb5">
                        <a href="" @click.prevent="signOutUser" class="b">Logout</a>
                    </div>
                </form>
            </div>
            <!-- Verification input -->
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { computed, reactive, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import VOtpInput from "vue3-otp-input";
import Alert from '@/components/Alert'

export default {
    name: 'ResetPassword',
    components: { Alert, VOtpInput },

    setup() {
        const store = useStore()
        const route = useRoute()
        const otpInput = ref(null);
        const bindModal = ref("");
        const message = computed(() => '')
        const state = reactive({
            disableSubmitButton: true,
            otp: ''
        })

        const handleOnComplete = (value) => {
            state.otp = value;
            state.disableSubmitButton = false
        };

        const onSubmit = () => {
            state.disableSubmitButton = true

            const { disableSubmitButton, ...rest } = state

            store.dispatch('Auth/verifyEmailViaOTP', rest).then(result => {
                if (result?.success) {
                    state.disableSubmitButton = false
                } else {
                    state.disableSubmitButton = false
                }
            })
        }

        const resendOTP = () => {
            store.dispatch('Auth/resendOTP').then(data => {
                if (data?.success) {
                    state.otp = ''
                }
            })
        }

        const signOutUser = () => store.dispatch('Auth/signOut')

        onMounted(() => {})

        return {
            otpInput,
            bindModal,
            state,
            message,
            handleOnComplete,
            onSubmit,
            resendOTP,
            signOutUser
        }
    }
}
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
